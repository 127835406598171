import React from "react"
import Modal from "react-modal"
import { Link, navigate } from "gatsby"
import { use100vh } from "react-div-100vh"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { FeedbackConfig, SiteMetaConfig, FormConfig } from "../config/config-data"
import "../styles/global.css"


const FeedbackPage = ({ location }) => {

  const [ modalIsOpen, setIsOpen ] = React.useState(false);
  
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  React.useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [modalIsOpen]);




  const [realPassword, setRealPassword] = React.useState('')

  const handlePasswordChange = event => {
    setRealPassword(event.target.value);
  };
  

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    data.delete('Password');
    const values = Object.fromEntries(data.entries());
    if (realPassword.trim().length !== 0) {
      {FormConfig.form_spam_email &&
        fetch(FormConfig.form_spam_email, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          },
          body: JSON.stringify(values)
        })
      }
    } else {
      fetch(FormConfig.form_email, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify(values)
      })
    }
  };


  return(
  <>
    <GatsbySeo
      title={`Give Us Your Valuable Feedback | ${SiteMetaConfig.site_name}`}
      description={SiteMetaConfig.site_description}
      canonical={`${SiteMetaConfig.site_url}${location.pathname}`}
      openGraph={{
        type: "website",
        url: `${SiteMetaConfig.site_url}${location.pathname}`,
        title: `Give Us Your Valuable Feedback | ${SiteMetaConfig.site_name}`,
        description: SiteMetaConfig.site_description,
        images: [
          { url: SiteMetaConfig.site_image },
        ],
        site_name: SiteMetaConfig.site_name,
      }}
      twitter={{
        handle: `@${SiteMetaConfig.site_twitter_handle}`,
        site: `@${SiteMetaConfig.site_name}`,
        cardType: "summary_large_image",
      }}
      facebook={{
        appId: SiteMetaConfig.site_facebook_app_id,
      }}
      htmlAttributes={{
        lang: SiteMetaConfig.site_language,
      }}
      noindex={true}
      nofollow={true}
    />
    <div id="feedback_1662797147011" style={{minHeight: use100vh(), display: "grid", alignItems: "center"}}>
      <div id="feedback_1662797150874" className="Card Card_Container">
        <div id="feedback_1662797152307" className="Sub_Heading_Text">Are you satisfied with our service?</div>
        <div id="feedback_1662797153547" className="Extra_Spacing" />
        <div id="feedback_1662797154643" style={{marginTop:"10px"}} className="Flex_Container">
          {FeedbackConfig.review_submission_link
          ?
          <a id="feedback_1662797155987" href={FeedbackConfig.review_submission_link} rel="noopener noreferrer"><button id="feedback_1662797162011">YES, I AM</button></a>
          :
          <button id="feedback_1662797157435" onClick={openModal}>YES, I AM</button>
          }
          <Link id="feedback_1662797158715" to="/feedback-problem"><button id="feedback_1662797160483">NO, I AM NOT</button></Link>
        </div>
      </div>
    </div>
    <Modal
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={false}
      contentLabel="Lead Modal"
    >
      <svg className="Modal_Close_Button" alt="Modal Close Button" onClick={closeModal} title="Close Button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
        <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/>
      </svg>
      <div id="feedback_1662797164499" className="Sub_Heading_Text">Please give us your info so we can provide you good service in the future too!</div>
      <div id="feedback_1662797165993" className="Card_Container">
        <form id="feedback_1662797166707" onSubmit={handleSubmit}>
          <label className="Real_Password" htmlFor="Password">Password:</label>
          <input
            id="Password"
            name="Password"
            type="password"
            className="Real_Password"
            onChange={handlePasswordChange}
            autoComplete="new-password"
          />
          <div id="feedback_1662797168722" style={{textAlign:"left"}}>
            <label id="feedback_1662797169761" className="Text_Card">
              <div id="feedback_1662797171297" className="Normal_Text">Email:</div>
              <input id="feedback_1662797172144" type="email" name="Email Address" className="Text_Input" required/>
            </label>
            <div id="feedback_1662797174137" className="Extra_Spacing" />
            <label id="feedback_1662797175209" className="Text_Card">
              <div id="feedback_1662797176472" className="Normal_Text">First Name:</div>
              <input id="feedback_1662797178233" type="text" name="First Name" className="Text_Input" required/>
            </label>
            <div id="feedback_1662797179786" className="Extra_Spacing" />
            <label id="feedback_1662797180777" className="Text_Card">
              <div id="feedback_1662797181817" className="Normal_Text">Last Name:</div>
              <input id="feedback_1662797182729" type="text" name="Last Name" className="Text_Input" required/>
            </label>
            <input id="feedback_1662797183722" type="hidden" name="_subject" value="You Received A Good Feedback!" />
          </div>
          <div id="feedback_1662797185161" className="Extra_Spacing" />
          <button id="feedback_1662797186809" type="submit" onClick={() => {navigate("/thankyou/")}}>SUBMIT</button>
        </form>
      </div>
    </Modal>
  </>
  )
}

export default FeedbackPage